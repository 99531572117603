import styled from 'styled-components';

export const BlogCardRoot = styled.li`
    background-color: ${props => props.theme.palette.white.main};
    color: ${props => props.theme.palette.white.contrast};
    border-radius: ${props => props.theme.radius.large};
    padding: 2.5rem;
    margin: auto;
    box-shadow: ${props => props.theme.shadow.md};
    display: inline-block;
    width: ${props => props.theme.blog.card.width.small};
    max-width: ${props => props.theme.blog.card.width.small};
    height: 100%;
    display: flex;
    flex-direction: column;

    h4 {
        min-height: ${props => props.theme.blog.card.title.height};
        display: flex;
        align-items: center;
    }

    @media only screen and (min-width: ${props => props.theme.breakpoint[props.breakpoint] || props.theme.breakpoint.desktop}px) {
        width: ${props => props.theme.blog.card.width.large};
        max-width: ${props => props.theme.blog.card.width.large};
        min-height: ${props => props.theme.blog.card.height};
        margin-top: 0;
    }
`;

export default {
    root: BlogCardRoot,
}