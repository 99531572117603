import styled from 'styled-components';

export const BannerContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;    
    min-height: calc(100vh - ${props => props.theme.header.height});
    padding: 2rem 0;
`;

export default {
    content: BannerContent,
}