import styled from 'styled-components';

export default styled.div`
    position: relative;
    padding: 0 3rem;

    &, * {
        outline: none;
    }

    ul.slider-list {
        &, li {
            cursor: auto;
        }
    }

    span[class*="-arrow"] {
        height: 2rem;
    }

    [class*="slider-control"] {
        opacity: 0.2;

        &:hover {
            opacity: 0.8;
        }
    }
`;