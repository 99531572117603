import styled from 'styled-components';

export default styled.div`
    padding: 5rem 2rem;
    max-width: ${props => props.theme.card.maxWidth};
    margin: auto;

    a {
        &:hover {
            color: ${props => props.theme.palette.primary.bright} !important;
        }
    }
`;