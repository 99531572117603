import React from 'react';
import Carousel from 'nuka-carousel';
import CarouselRoot from './carousel.css';
import TestimonialsCard from './card';
import Icon from 'src/components/base/icon';
import useTheme from 'src/components/base/useTheme';

const TestimonialsCarousel = ({ testimonials, images }) => {

  const theme = useTheme();
  const [width, setWidth] = React.useState(0);

  const updateWindowDimensions = () => {
    if (window) {
      setWidth(window.innerWidth);
    }
  }

  React.useEffect(() => {
    if (window) {
      // on mount
      updateWindowDimensions();
      window.addEventListener('resize', updateWindowDimensions);
  
      // on unmount
      return function cleanup() {
        window.removeEventListener('resize', updateWindowDimensions);
      };
    }
  }, []);

  const mapTestimonials = () => {
    return testimonials.map((node, i) => {
      // match image node to profile's image url
      const image = images.find(img => img.relativePath === node.frontmatter.imagePath);
      return (
        <TestimonialsCard
          key={i}
          testimonial={node}
          image={image && <img src={image.publicURL} alt={node.frontmatter.name} />}
        />
      )
    });
  }

  return (
    <CarouselRoot>
      <Carousel
        slidesToShow={width > theme.breakpoint.desktop ? 2 : 1}
        wrapAround={true}
        slideWidth={1}
        heightMode="max"
        initialSlideHeight={parseInt(theme.card.maxHeight.replace(/[0-9]/g, ''), 10)}
        intiialSlideWidth={parseInt(theme.card.maxWidth.replace(/[0-9]/g, ''), 10)}
        renderCenterLeftControls={({ previousSlide }) => (
          <Icon size="1.8rem" style={{ cursor: 'pointer', transform: 'translate(-50%, 0)' }}>
            <span className="left-arrow" onClick={previousSlide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="451.846"
                height="451.847"
                viewBox="0 0 451.846 451.847"
                style={{transform: 'rotate(180deg)'}}
              >
                <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744 L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z" />
              </svg>
            </span>
          </Icon>
        )}
        renderCenterRightControls={({ nextSlide }) => (
          <Icon size="1.8rem" style={{ cursor: 'pointer', transform: 'translate(50%, 0)' }}>
            <span className="right-arrow" onClick={nextSlide}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="451.846"
                height="451.847"
                viewBox="0 0 451.846 451.847"
              >
                <path d="M345.441,248.292L151.154,442.573c-12.359,12.365-32.397,12.365-44.75,0c-12.354-12.354-12.354-32.391,0-44.744 L278.318,225.92L106.409,54.017c-12.354-12.359-12.354-32.394,0-44.748c12.354-12.359,32.391-12.359,44.75,0l194.287,194.284 c6.177,6.18,9.262,14.271,9.262,22.366C354.708,234.018,351.617,242.115,345.441,248.292z" />
              </svg>
            </span>
          </Icon>
        )}
      >
        {mapTestimonials()}
      </Carousel>
    </CarouselRoot>
  )
}

export default TestimonialsCarousel;