import React from 'react';
import PropTypes from 'prop-types';
import Section from 'src/components/base/section';
import Banner from './banner.css';

const HeroBanner = ({ children, color, style}) => {

    return (
        <Section color={color} style={{position: 'relative', ...style}}>
            <Banner.content>
                { children }
            </Banner.content>
        </Section>
    );
}

HeroBanner.propTypes = {
    children: PropTypes.node,
    color: PropTypes.string,
    style: PropTypes.object,
}

HeroBanner.defaultProps = {
    style: {},
}

export default HeroBanner;