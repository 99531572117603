import React from 'react';
import PropTypes from 'prop-types';
import Container from 'src/components/base/container';
import Section from 'src/components/base/section';
import Grid from 'src/components/base/grid';
import BlogCard from './card';
import useTheme from 'src/components/base/useTheme';

const BlogList = ({ blogPosts }) => {

    const theme = useTheme();
    const breakpoint = 'tablet';

    return (
        <Container breakpoint={breakpoint} large={theme.blog.list.maxWidth}>
            {blogPosts.map((blogPost, i, allPosts) => {
                // only render every other blog post starting at index 0
                if (i%2 !== 0) return null;
                // render current and next blog post in same responsive row
                const nextBlogPost = allPosts[i+1];
                return (
                    <Section top={theme.blog.list.gap} key={i}>
                        <Grid.row key={i} breakpoint={breakpoint} gap={i > 0}>
                            <Grid.column size="6">
                                <BlogCard data={blogPost} breakpoint={breakpoint} />
                            </Grid.column>
                            {nextBlogPost && (
                                <Grid.column size="6" gap>
                                    <BlogCard data={nextBlogPost} breakpoint={breakpoint} />
                                </Grid.column>
                            )}
                        </Grid.row>
                    </Section>
                );
            })}
        </Container>
    );
}

BlogList.propTypes = {
    blogPosts: PropTypes.array,
}

export default BlogList;