import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'src/components/base/flex';
import Typography from 'src/components/base/typography';
import Section from 'src/components/base/section';
import LinkExternal from 'src/components/base/linkExternal';
import Icon from 'src/components/base/icon';
import EmailIcon from "src/images/icons/mailwhite.svg";
import GithubIcon from "src/images/icons/githubwhite.svg";
import LinkedinIcon from "src/images/icons/linkedinwhite.svg";
import ProfileCardRoot from './card.css';

const ProfileCard = ({ profile, image }) => {

  const gap = "2rem";

  const IconLink = ({ icon, href }) => (
    <Section right="1rem">
      <LinkExternal href={href} data-hover="color">
        <Icon size="1.5rem">
          {icon}
        </Icon>
      </LinkExternal>
    </Section>
  )

  return (
    <ProfileCardRoot>
      <Flex.box direction="column" align="stretch">
        <Icon size="10rem" margin="auto" circle contain>{image}</Icon>
        <Section y={gap}>
          <Typography
            as="h3"
            size="2"
            family="display"
            weight="bold"
            align="center"
            block
          >
            {profile.frontmatter.name}
            {profile.frontmatter.suffix
              ? `, ${profile.frontmatter.suffix}`
              : ""}
          </Typography>
        </Section>
        <Typography
          lineHeight="1.5em"
          dangerouslySetInnerHTML={{ __html: profile.excerpt }}
        />
        <Section y=".5rem">
          <Typography color="primary" contents>
            <Flex.box direction="row" align="center" style={{ justifyContent: 'center' }}>
              <IconLink
                icon={<EmailIcon />}
                href={"mailto:" + profile.frontmatter.email}
              />
              <IconLink
                icon={<GithubIcon />}
                href={profile.frontmatter.github}
              />
              <IconLink
                icon={<LinkedinIcon />}
                href={profile.frontmatter.linkedin}
              />
            </Flex.box>
          </Typography>
        </Section>
      </Flex.box>
    </ProfileCardRoot>
  )
}

ProfileCard.propTypes = {
  profile: PropTypes.object.isRequired,
}

export default ProfileCard;