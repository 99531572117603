import React from "react";
import Section from 'src/components/base/section';
import Icon from 'src/components/base/icon';
import Flex from 'src/components/base/flex';
import Typography from 'src/components/base/typography';
import LinkExternal from 'src/components/base/linkExternal';
import Divider from 'src/components/base/divider';
import TestimonialsCardRoot from './card.css';

const TestimonialsCard = ({ testimonial, image }) => {

  return (
    <TestimonialsCardRoot>
        {/* Top */}
        <Flex.item grow="1">
          <Icon size="4rem" margin="auto" circle>{image}</Icon>
          <Section top="1rem">
            <Typography align="center" contents>
              <Section bottom="1rem">
                <Typography as="blockquote" size="4" family="display" color="gray" weight="medium" italic block>
                  "{testimonial.excerpt}"
                </Typography>
              </Section>
              {testimonial.frontmatter.fromSource && (
                <Section top=".5rem">
                  <Typography color="gray" contents>
                    <Divider size="2rem" margin=".5rem" />
                  </Typography>
                  <Typography block>
                    <span>From </span>
                    <LinkExternal href={testimonial.frontmatter.fromSourceURL} data-color="primary" data-hover="underline">
                      {testimonial.frontmatter.fromSource}
                    </LinkExternal>
                  </Typography>
                </Section>
              )}
            </Typography>
          </Section>
        </Flex.item>

        {/* Bottom */}
        <Section>
          <Typography align="center" contents>
            <Typography size="4" weight="bold" block>
              {testimonial.frontmatter.name}
              {testimonial.frontmatter.suffix ? `, ${testimonial.frontmatter.suffix}` : ''}
            </Typography>
            <Typography size="5" block>{testimonial.frontmatter.title}</Typography>
          </Typography>
        </Section>
    </TestimonialsCardRoot>
  )
}

export default TestimonialsCard;