import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from 'src/components/layout/main';
import SEO from 'src/components/seo';
import Container from 'src/components/base/container';
import Section from 'src/components/base/section';
import Flex from 'src/components/base/flex';
import Typography from 'src/components/base/typography';
import { H1, H2 } from 'src/components/heading/heading';
import Grid from 'src/components/base/grid';
import FeaturesLandscape from 'src/images/icons/features_landscape.svg';
import FeaturesPortrait from 'src/images/icons/features_portrait.svg';
import Icon from 'src/components/base/icon';
import ProfileCard from 'src/components/profile/card';
import TestimonialsCarousel from 'src/components/testimonials/carousel';
import BlogList from 'src/components/blog/list';
import HeroBanner from 'src/components/hero/banner';
import CaretRight from 'src/images/icons/caret_right.svg';

const IndexPage = (props) => {

    // get data from graphql api
    const profiles = props.data.profiles.nodes;
    const testimonials = props.data.testimonials.nodes;
    const images = props.data.images.nodes;
    const blogPosts = props.data.featuredBlogPosts.nodes;

    return (
        <Layout page="/">
            <SEO title="Home" />

            {/* Index Hero */}
            <HeroBanner color="dark">
                {/* Heading */}
                <Flex.item>
                    <Flex.box direction="column" justify="center" align="center">
                        <H1>
                            <Typography block style={{ marginLeft: 5, marginRight: 5 }}>We Build Elite Technology</Typography>
                        </H1>
                    </Flex.box>
                </Flex.item>
                {/* Feature Badges */}
                <Section x="2rem" y="4rem" contents>
                    <Container small="500px">
                        <Icon width="100%" margin="auto" data-hidden="desktop">
                            <FeaturesPortrait />
                        </Icon>
                        <Icon height="100%" margin="auto" data-hidden="mobile tablet">
                            <FeaturesLandscape />
                        </Icon>
                    </Container>
                </Section>
            </HeroBanner>

            {/* Profiles */}
            <Section color="silver" y="2rem">
                <Container style={{ width: '80%' }}>
                    <Grid.row >
                        {profiles.map((profile, i) => {

                            // match image node to profile's image url
                            const image = images.find(img => img.relativePath === profile.frontmatter.imagePath);

                            return (
                                <Grid.column size="4" key={i}>
                                    <ProfileCard
                                        profile={profile}
                                        image={<img src={image.publicURL} alt={profile.name} style={{ objectFit: 'cover' }} />}
                                    />
                                </Grid.column>
                            );
                        })}
                    </Grid.row>
                </Container>
            </Section>

            {/* Testimonials */}
            <Section color="white" y="4rem">
                <Container>
                    <TestimonialsCarousel testimonials={testimonials} images={images} />
                </Container>
            </Section>

            {/* Contact Action */}
            <Section color="silver" y="3rem">
                <Typography align="center" color="primary" contents>
                    <H2 as="div">
                        <Link to="/contact" data-hover="fancyline">
                            Let's talk about your project
                            <Icon size="1em" style={{ display: 'inline-block', transformOrigin: '50% 100%', transform: 'scale(0.7)', marginLeft: '.5rem', marginRight: '-.5rem' }}>
                                <CaretRight />
                            </Icon>
                        </Link>
                    </H2>
                </Typography>
            </Section>

            {/* Featured Blog Posts */}
            <Section color="dark" y="6rem">
                <Section bottom="5rem">
                    <Typography size="2" family="display" weight="bold" align="center" block>
                        Featured Blog Posts
                    </Typography>
                </Section>
                <BlogList blogPosts={blogPosts} />
            </Section>
        </Layout>
    );
}

/*
 *  Query data:
 *   - profile data for Brent, Mark
 *   - testimonials
 *   - images
 *   - featured blog posts
 */

export const query = graphql`
    query {
        profiles: allMarkdownRemark(
            filter: { fields: { sourceName: { eq: "people" } } },
            sort: { order: ASC, fields: frontmatter___order }
        ) {
            nodes {
                id
                excerpt (pruneLength: 500, format: HTML)
                frontmatter {
                    order
                    name
                    suffix
                    imagePath
                    email
                    github
                    linkedin
                    resume
                }
                fields {
                    sourceName
                }
            }
        }
        testimonials: allMarkdownRemark(
            filter: { fields: { sourceName: { eq: "testimonials" } } }
        ) {
            nodes {
                id
                excerpt (pruneLength: 500)
                frontmatter {
                    name
                    suffix
                    title
                    imagePath
                    fromSource
                    fromSourceURL
                }
                fields {
                    sourceName
                }
            }
        }
        images: allFile(filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {in: "home"}}) {
            nodes {
                relativePath
                publicURL
            }
        }
        featuredBlogPosts: allContentfulBlog(
            sort: { order: DESC, fields: createdAt }
            filter: { featured: { eq: true } }
            limit: 4
        ) {
            nodes {
                id
                slug
                featuredImage {
                    id
                    file {
                        url
                    }
                }
                title
                shortDescription
            }
        }
    }
`

export default IndexPage;
