import styled from 'styled-components';

export default styled.div`
    height: 100%;
    max-height: ${props => props.theme.card.maxHeight};
    padding: 2rem;
    padding-bottom: 4rem;
    margin: auto;
    width: ${props => props.theme.card.maxWidth};
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
`;