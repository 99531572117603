import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Flex from 'src/components/base/flex';
import Section from 'src/components/base/section';
import Typography from 'src/components/base/typography';
import Icon from 'src/components/base/icon';
import Button from 'src/components/base/button';
import { BlogCardRoot } from './card.css';
import useTheme from 'src/components/base/useTheme';

const BlogCard = ({ data, responsiveGap, breakpoint }) => {

    const theme = useTheme();

    const prune = (text, prune) => {
        if (!text) {
            return text
        }
        return text.length > prune + 3 ? text.slice(0, prune + 1) + (text.length > prune ? '...' : '') : text;
    }

    return (
        <BlogCardRoot breakpoint={breakpoint} responsiveGap={responsiveGap}>
            {/* Image */}
            <Section bottom="1rem">
                <Link
                    to={`/blog/${data.slug}`}
                >
                    <Icon height={theme.blog.card.image.size} margin="auto">
                        <img src={data.featuredImage ? data.featuredImage.file.url : ''} alt={data.title} />
                    </Icon>
                </Link>
            </Section>

            {/* Text */}
            <Flex.item grow="1">
                <Typography as="h4" size="4" family="display" weight="medium" italic block>
                    {data.title}
                </Typography>
                <Section top="1rem">
                    <Typography>
                        {prune(data.shortDescription, theme.blog.card.prune.description - data.title.length)}
                    </Typography>
                </Section>
            </Flex.item>

            {/* Action */}
            <Section top="1rem">
                <Link to={`/blog/${data.slug}`} data-hover="none">
                    <Button outline round block hoverColor={theme.palette.primary.alpha.light}>
                        Continue reading
                    </Button>
                </Link>
            </Section>

        </BlogCardRoot>
    );
}

BlogCard.propTypes = {
    data: PropTypes.object,
    responsiveGap: PropTypes.bool,
}

BlogCard.defaultProps = {
    responsiveGap: false,
}

export default BlogCard;